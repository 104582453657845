type ScreeningInputFields =
  | 'first_name'
  | 'last_name'
  | 'email'
  | 'phone'
  | 'cell'
  | 'driver_license_state'
  | 'driver_license_number'
  | 'postal_code'
  | 'city'
  | 'state'
  | 'country'
  | 'card_number'
  | 'cardholder_name'
  | 'card_exp'
  | 'card_cvv'
  | 'card_type'
  | 'billing_address1'
  | 'billing_address2'
  | 'billing_city'
  | 'billing_state'
  | 'billing_zip'
  | 'billing_country'
  | 'last4'
  | 'brand'
  | 'token';

type ScreeningInput = {
  [k in ScreeningInputFields]: string;
};

interface ScreeningInputValues extends ScreeningInput {
  useClover: boolean;
  id: number;
};
export interface ScreeningModalProps {
  onSubmitCallback?: (val: any, res: any) => void,
  onClose: () => void,
  screeningType: string,
  userId: number,
  source: string,
}
export interface PaymentFormProps {
  values: ScreeningInputValues,
  onChange: (values: ScreeningInputValues) => void;
  formError: Partial<ScreeningInputValues>,
  checkValidation: () => boolean;
}
export interface OrderFormProps extends PaymentFormProps {
  screeningType: string,
}
export interface Validator {
  type: 'error' | 'success';
  message: string;
}
export interface CountryLocationLabel {
  required: boolean;
  label: string;
}
export interface CountryStateLabel extends CountryLocationLabel {
  values: {
    value: string;
    label: string;
  }[];
}

export interface CountryZipLabel extends CountryLocationLabel {
  pattern: string;
}

export interface Country {
  label: string;
  value: string;
  name: string;
  iso: string;
  address1: CountryLocationLabel;
  address2: CountryLocationLabel;
  city: CountryLocationLabel;
  state: CountryStateLabel;
  zip: CountryZipLabel;
}
export interface ScreeningStatusModalProps {
  tryAgain: () => void;
  onClose: () => void;
  isSuccessful: boolean;
  screeningType: string;
  checkrUrl?: string;
}

export class ScreeningModalWidget extends React.Component<ScreeningModalProps, any> {}

export interface OrderStatus {
  url: string;
  success: boolean;
}

export const CLOVER_EVENT_SOURCE_ORDER_SCREENING = 'Order Screening';
export const CLOVER_PAYMENT_TYPE_SCREENING = 'screening_payment';
