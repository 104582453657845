import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GroupCardListItem, v2, Loader } from '@fieldnation/platform-components';
import { FooterAction } from '@fieldnation/platform-components/src/v2/Modal/types.d';
import { useRecoilValue } from 'recoil';
import css from './Styles.scss';
import PaymentForm from './payment-form';
import {
  ScreeningModalProps,
  ScreeningInputValues,
  OrderStatus,
  CLOVER_EVENT_SOURCE_ORDER_SCREENING,
  CLOVER_PAYMENT_TYPE_SCREENING,
} from './types.d';
import OrderForm from './order-form';
import {
  validateOrderFormInputs,
  validatePaymnetFormInputs,
} from './helpers';
import screeningFeeQuery from './Store/fee.query';
import { submitScreeningOrder } from './api';
import { screeningDefaultInputs, userDataQuery } from './Store/user.query';
import ScreeningStatusModal from './screening-status-modal';
import { EVENTS, useAmplitudeTracker } from '../Amplitude/useAmplitudeTracker';

const { Modal } = v2;

const ScreeningModal = ({
  onSubmitCallback,
  onClose,
  screeningType,
  source,
}: ScreeningModalProps): JSX.Element => {
  const userInfo = useRecoilValue(userDataQuery);
  const fee = useRecoilValue(screeningFeeQuery);
  const { screeningTotalFee, isFreeScreening, packageId } = fee;

  const [values, setValues] = useState<ScreeningInputValues>(screeningDefaultInputs);
  const [loading, setLoading] = useState<boolean>(false);
  const [showScreeningModal, setShowScreeningModal] = useState<boolean>(true);
  const [showOrderForm, setShowOrderForm] = useState<boolean>(true);
  const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false);
  const [userValidation, setUserValidation] = useState<Partial<ScreeningInputValues>>({});
  const [payValidation, setPayValidation] = useState<Partial<ScreeningInputValues>>({});
  const [orderStatus, setOrderStatus] = useState<OrderStatus>({ url: '', success: false });
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const trackOnAmplitude = useAmplitudeTracker();

  const validateUserInputs = () => validateOrderFormInputs(
    screeningType, values, setUserValidation,
  );

  const validatePayInputs = () => validatePaymnetFormInputs(
    values, setPayValidation,
  );

  const handleOnClose = () => {
    setShowOrderForm(false);
    setShowPaymentForm(false);
    setShowScreeningModal(false);
    onClose();
  };

  const handleConfirmationModalClose = () => {
    setShowStatusModal(false);
    handleOnClose();
  };

  const handleTryAgain = () => {
    setShowStatusModal(false);
    setShowScreeningModal(true);
    setShowOrderForm(true);
    setShowPaymentForm(false);
    setValues(userInfo);
  };

  const handleBackToScreening = () => {
    setShowOrderForm(true);
    setShowPaymentForm(false);
  };



  const onCloverTokenGenerated = (brand: string, last4: string, token: string) => {
    setValues({
      ...values,
      brand,
      last4,
      token,
    });
    submitOrderFormForClover(brand, last4, token);
  }

  const handleGoToPayment = () => {
    if (validateUserInputs()) {
      if (!values?.useClover) {
        setShowOrderForm(false);
        setShowPaymentForm(true);
      }

      trackOnAmplitude(EVENTS.CONFIRMED_SCREENING_ORDER_DETAILS, {
        userId: userInfo?.id,
        screeningType,
        Source: source,
        device: 'web',
        'Package Cost': screeningTotalFee,
        state: values?.state,
        zip: values?.postal_code,
        country: values?.country,
      });

      if (values?.useClover) {
        trackOnAmplitude(EVENTS.PROCESSED_CREDIT_CARD_PAYMENT, {
          Source: CLOVER_EVENT_SOURCE_ORDER_SCREENING,
        });
        setShowScreeningModal(false);        
        // @ts-ignore
        window?._fnRedux?.dispatch({
          type: 'APP_TOGGLE_ON',
          flag: 'clover_modal',
          value: {
              amount: screeningTotalFee,
              charge: 0,
              totalAmount: screeningTotalFee,
              paymentType: CLOVER_PAYMENT_TYPE_SCREENING,
              pageSource: CLOVER_EVENT_SOURCE_ORDER_SCREENING,
              submitButtonText: 'Order Screening',
              isFreeScreening,
              screeningType,
              onCloverTokenGenerated,
              onBackButtonClicked: () => setShowScreeningModal(true),
          },
        });
      }

    }
  };

  const submitOrderFormForClover = async (brand: string, last4: string, token: string) => {
    setShowScreeningModal(true);
    setLoading(true);
    // submitting the screening order
    if (!isFreeScreening) {
      trackOnAmplitude(EVENTS.SUBMITTED_SCREENING_PAYMENT, {
        userId: userInfo?.id,
        screeningType,
        Source: source,
        device: 'web',
        'Package Cost': screeningTotalFee,
        state: values?.state,
        zip: values?.postal_code,
        country: values?.country,
      });
    }
    const formData = {
      ...values,
      brand,
      last4,
      token,
    };
    const resp = await submitScreeningOrder(userInfo, packageId, formData);
    if (resp) {
      trackOnAmplitude(EVENTS.PROCESSED_CREDIT_CARD_PAYMENT, {
        Source: CLOVER_EVENT_SOURCE_ORDER_SCREENING,
        Amount: screeningTotalFee || 0,
      });
      setOrderStatus({ url: resp?.status?.external_action_url, success: true });
    }
    // this is a callback function, after submit order it should call
    if (onSubmitCallback) {
      onSubmitCallback(values, resp);
    }

    setLoading(false);
    setShowStatusModal(true);
    setShowScreeningModal(false);
  };
 
  const submitOrderForm = async () => {
    const isOrderFormValid = validateUserInputs();
    const isPaymentFormValid = validatePayInputs();
    if (
      isOrderFormValid
      && (
        isPaymentFormValid
        || isFreeScreening === true
      )
    ) {
      setLoading(true);
      // submitting the screening order
      if (!isFreeScreening) {
        trackOnAmplitude(EVENTS.SUBMITTED_SCREENING_PAYMENT, {
          userId: userInfo?.id,
          screeningType,
          Source: source,
          device: 'web',
          'Package Cost': screeningTotalFee,
          state: values?.state,
          zip: values?.postal_code,
          country: values?.country,
        });
      }

      const resp = await submitScreeningOrder(userInfo, packageId, values);
      if (resp) {
        setOrderStatus({ url: resp?.status?.external_action_url, success: true });
      }
      // this is a callback function, after submit order it should call
      if (onSubmitCallback) {
        onSubmitCallback(values, resp);
      }

      setLoading(false);
      setShowStatusModal(true);
      setShowScreeningModal(false);
    }
  };

  useEffect(() => {
    setValues(userInfo);
  }, [userInfo]);

  useEffect(() => {
    validateUserInputs();
    if (!values?.useClover) {
      validatePayInputs();
    }
  }, [values]);

  const getFooterActions = (): FooterAction[] => {
    const orderFormActions: FooterAction[] = [
      {
        label: isFreeScreening === true ? 'Order Screening' : 'Continue to payment',
        type: 'primary',
        disabled: Object.keys(userValidation || {})?.length !== 0,
        onClick: isFreeScreening === true ? submitOrderForm : handleGoToPayment,
      },
    ];
    const paymentFormActions: FooterAction[] = [
      {
        label: 'Back',
        type: 'text',
        onClick: handleBackToScreening,
      },
      {
        label: 'Order Screening',
        type: 'primary',
        disabled: Object.keys(payValidation || {})?.length !== 0,
        onClick: submitOrderForm,
      },
    ];

    return showPaymentForm ? paymentFormActions : orderFormActions;
  };

  return (
    <div className="ScreeningModal">
      {showStatusModal && (
        <ScreeningStatusModal
          tryAgain={handleTryAgain}
          onClose={handleConfirmationModalClose}
          screeningType={screeningType}
          isSuccessful={orderStatus.success}
          checkrUrl={orderStatus.url}
        />
      )}
      <Modal
        size="medium"
        header={`Order ${screeningType}`}
        footerActions={getFooterActions()}
        onClose={handleOnClose}
        isOpen={showScreeningModal}
      >
        <Loader fixed isLoading={loading} />
        <div className={css.checkrFeeWrapper}>
          <div className={css.feeCard}>
            <GroupCardListItem
              hideExpander
              dottedLine
              middleContent=""
              label={`Checkr ${screeningType}`}
              data={(
                <span className={css.priceRate}>
                  {isFreeScreening ? (
                    <>
                      <del className={css.delPrice}>{`${screeningTotalFee}`}</del>
                      <span className={css.freeText}>FREE</span>
                    </>
                  ) : screeningTotalFee}
                </span>
              )}
              type="default"
              border="none"
            />
          </div>
          <hr className={css.divider} />
        </div>
        {showOrderForm && (
          <OrderForm
            screeningType={screeningType}
            values={values}
            onChange={setValues}
            formError={userValidation}
            checkValidation={validateUserInputs}
          />
        )}
        {showPaymentForm && (
          <PaymentForm
            values={values}
            onChange={setValues}
            formError={payValidation}
            checkValidation={validatePayInputs}
          />
        )}
      </Modal>
    </div>
  );
};

ScreeningModal.propTypes = {
  onSubmitCallback: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  screeningType: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
};

ScreeningModal.defaultProps = {
  onSubmitCallback: () => {},
};

export default ScreeningModal;
