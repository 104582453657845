export interface ICompany {
  id: number;
  name: string;
  phone?: string;
  email?: string;
  photo?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  website?: string;
}

export interface IUserGroup {
  acting_role_id?: number;
  id?: UserGroup;
  name?: number;
  original_id?: number;
  original_name?: string;
}

export interface IFeature {
  results: IBuyerFeature[];
}

export interface IBuyerFeature {
  id?: number;
}

export interface IContext {
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  group?: IUserGroup;
  features?: IFeature;
  experiments?: IJsObject;
  company?: ICompany;
  permissions?: string[];
  actions?: string[];
}

export type IGridSize = {
  LARGE: ColumnUnit | undefined;
  MEDIUM: ColumnUnit | undefined;
  SMALL: ColumnUnit | undefined;
};

declare global {
  interface Window {
    company?: ICompany;
    context?: IContext;
  }
}

export enum BuyerFeature {
  BUY_FROM_MARKETPLACE = 4,
  PERFORM_MARKETPLACE_WORK = 33,
}

export enum UserGroup {
  PROVIDER = 1,
  ADMIN = 2,
  STAFF = 3,
  CALL_COORDINATOR = 4,
  SUPERVISOR = 5,
  MANAGER = 7,
  DISPATCHER = 8,
  ACCOUNTANT = 9,
  CUSTOMER = 10,
  COMPANY_PAYMENT_USER = 11,
}

export const Country = {
  'BD': 'Bangladesh',
  'CA': 'Canada',
  'FR': 'France',
  'DE': 'Germany',
  'IN': 'India',
  'GB': 'United Kingdom',
  'US': 'United States of America',
};

export enum FileType {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
}

export const GridSize: IGridSize = {
  LARGE: '12',
  MEDIUM: '9',
  SMALL: '3',
};

export type IFullAddressInputFields =
  | 'address1'
  | 'address2'
  | 'city'
  | 'zip'
  | 'state'
  | 'country';

export type IFullAddressInputValues = {
  [k in IFullAddressInputFields]: string;
};

export interface IUseAddressInputAttribute {
  inputAttribute: IFullAddressInputAttribute;
  canSubmit: boolean;
}

export interface IJsObject<T = any> {
  [key: string]: T;
}